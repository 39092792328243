import React from "react";

type Props = {
    children: React.ReactNode;
};

const NonAuthLayout: React.FC<Props> = (props: Props) => {
    document.title = "TAQA - Dashboard";

    return <React.Fragment>{props.children}</React.Fragment>;
};

export default NonAuthLayout;
