import React, { useEffect, useState } from "react";
import { Card, CardBody, Spinner } from "reactstrap";
import { formatDistanceToNow } from "date-fns";
import { arSA } from "date-fns/locale";
import { Link } from "react-router-dom";
import { get } from "../../helpers/API";

import * as XLSX from 'xlsx';


type Attempt = {
  id: number;
  name: string;
  correctAnswers: number;
  attempts: number;
  timeSpent: number;
  passed: boolean;
  createdAt: string; // Adjust this if 'createdAt' is not a string
};

type ResponseType = {
  message: string;
  success: boolean;
  attempts: Attempt[];
};

const RecentAttemptsTable: React.FC = () => {
  const [attempts, setAttempts] = useState<Attempt[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    get("/admin/recent_passed_attempts")
      .then((response: ResponseType) => {
        const attempts = response.attempts;
        setAttempts(attempts);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  function formatTime(seconds: number): string {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min}:${sec.toString().padStart(2, "0")}`;
  }

  function exportData() {
    setIsLoading(true);
    get("/admin/all_passed_attempts")
      .then((response: ResponseType) => {
        console.log(response);
        const attempts = response.attempts;
        
        // Create a worksheet
        const ws = XLSX.utils.json_to_sheet(attempts);

        // Create a workbook with the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Export the workbook as an Excel file
        XLSX.writeFile(wb, "exported_data.xlsx");

        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="float-end d-flex align-items-center">
            {/* <Link to="/attempts" className="text-primary fw-bold">
              مشاهدة الجميع
              <i className="mdi mdi-arrow-expand-all ms-1 fw-bold fs-5"></i>
            </Link> */}
            <Link
              to="#"
              onClick={exportData}
              className="fw-bold fs-5"
              style={{ color: "#04bc5d" }}
            >
              {isLoading ? (
                <>
                  جاري التصدير... <Spinner size="sm" />
                </>
              ) : (
                <>
                  تصدير الكل إلى Excel{" "}
                  <i className="mdi mdi-microsoft-excel"></i>
                </>
              )}
            </Link>
          </div>
          <h4 className=" mb-4 fw-bold">آخر المشاركات الناجحة</h4>
          <div className="table-responsive">
            <table className="table table-nowrap table-hover mb-0">
              <thead>
                <tr>
                  <th scope="col">الاسم</th>
                  <th scope="col">المدة المستغرقة</th>
                  <th scope="col">عدد المحاولات</th>
                  <th scope="col">النقاط</th>
                  <th scope="col">وقت التسليم</th>
                </tr>
              </thead>
              <tbody>
                {attempts!.map((attempt: Attempt) => (
                  <tr key={attempt.id}>
                    <td>{attempt.name}</td>
                    <td>{formatTime(attempt.timeSpent)}</td>
                    <td>{attempt.attempts}</td>
                    <td>{attempt.correctAnswers}</td>
                    <td>
                      {formatDistanceToNow(new Date(attempt.createdAt), {
                        addSuffix: true,
                        locale: arSA,
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RecentAttemptsTable;
