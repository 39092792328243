export const setToken = (token: string): void => {
  const expiryTime = new Date();
  expiryTime.setDate(expiryTime.getDate() + 30); // Set token to expire in 30 days
  localStorage.setItem('authToken', JSON.stringify({ token, expiry: expiryTime.toISOString() }));
};

export const getToken = (): string | null => {
  const item = localStorage.getItem('authToken');
  if (!item) return null;
  
  const data = JSON.parse(item);
  return data.token;
};

export const isAuthenticated = (): boolean => {
  const item = localStorage.getItem('authToken');
  if (!item) return false;
  
  const data = JSON.parse(item);
  const isTokenValid = new Date().getTime() < new Date(data.expiry).getTime();
  
  if (!isTokenValid) {
    removeToken(); // If token is expired, remove it
  }

  return isTokenValid;
};

export const removeToken = (): void => {
  localStorage.removeItem('authToken');
};

export const login = (username: string, password: string): boolean => {
  // Simulated API call. In real scenario, you'd fetch from server.
  if (username === "admin" && password === "admin") {
    setToken('mockToken');
    return true;
  }
  return false;
};

export const logout = (): void => {
  removeToken();
};
