import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../helpers/AuthHelper';

const Logout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Perform logout operation
    performLogout();

    // Redirect to login or another page after logout
    navigate('/login'); // Adjust this to your login route
  });

  const performLogout = () => {
    removeToken();
  };

  return (
    <div>
      Logging out...
    </div>
  );
};

export default Logout;
