import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import avatar from '../assets/images/icon.png'; // Adjust the import path as necessary


const ProfileMenu: React.FC = () => {
  const [menu, setMenu] = useState<boolean>(false);

  const toggle = () => {
    setMenu(prevMenu => !prevMenu);
  };
  
  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block user-dropdown">
        <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
          <img className="rounded-circle header-profile-user me-1" src={avatar} alt="Header Avatar" />
          <span className="d-none d-xl-inline-block ms-1 text-transform">Admin</span>
          <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem className="text-danger" href="/logout"><i className="ri-shut-down-line align-middle me-1 text-danger"></i> تسجيل خروح</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
