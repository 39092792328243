import React, { FC } from 'react';
import { Col, Card, CardBody } from "reactstrap";
import CountUp, { CountUpProps } from 'react-countup';

interface DetailsCardProps {
    title: string;
    counterOptions: CountUpProps;
    icon: string;
}

const DetailsCard: FC<DetailsCardProps> = ({ title, counterOptions, icon }) => {
    return (
        <React.Fragment>
            <Col xl={3} md={6}>
                <Card>
                    <CardBody className="d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className="text-truncate mb-3">{title}</h5>
                            <h4 className="mb-0">
                                <CountUp
                                    duration={1}
                                    {...counterOptions}
                                />
                            </h4>
                        </div>
                        <div className=" d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
                            <i style={{color:'04bc5d'}} className={`${icon} h1 m-0`}></i>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default DetailsCard;
