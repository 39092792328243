import React from "react";
import { Routes as Router, Route, Navigate, Outlet } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import { isAuthenticated } from "./helpers/AuthHelper";
import Logout from "./pages/Logout";

import VerticalLayout from "./components/VerticalLayout";
import NonAuthLayout from "./components/NonAuthLayout";

type Props = {};

const PrivateRoutes = () => {
  const authenticated = isAuthenticated();

  if (!authenticated) return <Navigate to="/login" replace />;

  return <Outlet />;
};

const Routes: React.FC = (props: Props) => {
  return (
    <Router>
      <Route path="/login" element={<NonAuthLayout children={<Login />} />} />
      <Route element={<PrivateRoutes />}>
        <Route
          path="/dashboard"
          element={<VerticalLayout
            leftSideBarTheme="dark"
            layoutWidth="fluid"
            leftSideBarType="default"
            topbarTheme="light"
            theme="light"
            showRightSidebar={false}
            children={<Dashboard />} />}
        />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<Navigate replace to="/dashboard" />} />
      </Route>
    </Router>
  );
};

export default Routes;
