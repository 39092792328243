import React from "react";

import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumb";

import MainStatsCards from "./MainStatsCards";
import PassPiChart from "./PassPiChart";
import AttemptsOverTime from "./AttemptsOverTime";
import RecentAttemptsTable from "./RecentAttemptsTable";
import ScreenDetails from "./ScreenDetails";

type Props = {};

const Dashboard = (props: Props) => {
  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Menu", link: "#" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="لوحة التحكم" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Row>
              <Col xl={12}>
                <MainStatsCards />
              </Col>
              <Col xl={8}>
                <AttemptsOverTime />
              </Col>
              <Col xl={4}>
                <ScreenDetails />
              </Col>
            </Row>
            <Row>
              <Col xl={8}>
                <RecentAttemptsTable />
              </Col>
              <Col xl={4}>
                <PassPiChart />
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
