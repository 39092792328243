import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";
import { get } from "../../helpers/API";
import { ApexOptions } from "apexcharts";

type ResponseType = {
  attempts: AttemptSeries;
};

type AttemptSeries = [number, number][];

const AttemptsOverTime: React.FC = () => {
  const [series, setSeries] = useState<{ name: string; data: AttemptSeries }[]>(
    [{ name: "Attempts", data: [] }]
  );
  const [options, setOptions] = useState<ApexOptions>({
    colors: ["#04bc5d"],
    chart: {
      type: "area",
      stacked: false,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "pan",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
    },
  });

  useEffect(() => {
    get("/admin/attempts_over_time")
      .then((response: ResponseType) => {
        const attempts = response.attempts;
        const twoWeeksAgo = new Date().getTime() - 14 * 24 * 60 * 60 * 1000;
        const oldestTime = attempts[0][0];
        const minAxis = oldestTime < twoWeeksAgo ? twoWeeksAgo : oldestTime;

        setSeries([{ name: "Attempts", data: attempts }]);
        setOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            min: minAxis,
          },
        }));
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4 fw-bold">عدد المشاركات</h4>
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height="350"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AttemptsOverTime;
