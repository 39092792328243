import React from "react";
import { Link } from "react-router-dom";
import ProfileMenu from "../ProfileMenu";

// Import logo Images
import logosmdark from "../../assets/images/logo.png";
import logodark from "../../assets/images/logo.png";
import logosmlight from "../../assets/images/logo.png";
import logolight from "../../assets/images/logo.png";

type Props = {
  toggleMenuCallback: () => void;
};

const Header: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="#" className="ms-2 logo logo-dark">
                <span className="logo-sm">
                  <img src={logosmdark} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logodark} alt="" height="50" />
                </span>
              </Link>

              <Link to="#" className="ms-2 logo logo-light">
                <span className="logo-sm">
                  <img src={logosmlight} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logolight} alt="" height="50" />
                </span>
              </Link>
            </div>
          </div>

          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
