import React, { useEffect, ReactNode } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

type LayoutProps = {
  leftSideBarTheme: string;
  layoutWidth: string;
  leftSideBarType: string;
  topbarTheme: string;
  theme: string;
  showRightSidebar: boolean;
  children: ReactNode;
};

// set up the layout theme

const Layout: React.FC<LayoutProps> = ({
  leftSideBarTheme,
  layoutWidth,
  leftSideBarType,
  topbarTheme, // light
  theme,
  showRightSidebar,
  children,
}) => {
  // const [isMobile, setIsMobile] = useState<boolean>(
  //   /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  // );

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    let currentPage = window.location.pathname;
    currentPage = capitalizeFirstLetter(currentPage).replaceAll("-", " ");
    document.title = `${currentPage} | Nazox - Responsive Bootstrap 5 Admin Dashboard`;
    
    // remove preloader
    document!.getElementById("preloader")!.style!.display = "none";
    document!.getElementById("status")!.style!.display = "none";

    
  }, [
    leftSideBarTheme,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    theme,
    showRightSidebar,
  ]);

  const toggleMenuCallback = () => {};

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            {/* Replace with your loading spinner */}
            Loading...
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          // theme={leftSideBarTheme}
          type={leftSideBarType}
          // isMobile={isMobile}
        />
        <div className="main-content">
          {children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
