import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import CountUp from "react-countup";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { get } from "../../helpers/API";


type AttemptType = {
  passed: boolean;
  value: number;
};

type ResponseType = {
  message: string;
  success: boolean;
  attempts: AttemptType[];
};

const options: ApexOptions = {
  labels: ["النجاح", "الفشل"],
  dataLabels: {
    // enabled: false,
    formatter: function (
      val: string | number | number[],
      opts?: any
    ): string | number {
      const count = opts.w.config.series[opts.seriesIndex];
      return count.toLocaleString();
    },

    style: {
      fontSize: "15px",
      fontWeight: "bold",
    },
  },
  plotOptions: {
    pie: {
      donut: {
        size: "0%",
      },
    },
  },
  legend: {
    show: false,
  },
  colors: ["#04bc5d", "#000"],
  chart: {
    type: "pie",
    animations: {
      enabled: true,
      easing: "easeinout",
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },
  },
};

const PassPiChart: React.FC = () => {
  const [series, setSeries] = useState<number[]>([1, 1]);

  useEffect(() => {
    get("/admin/attempts_by_passed")
      .then((response: ResponseType) => {
        const passedAttempts = response.attempts.find((attempt) => attempt.passed);
        const failedAttempts = response.attempts.find((attempt) => !attempt.passed);
        
        const passed = passedAttempts ? passedAttempts.value : 0;
        const failed = failedAttempts ? failedAttempts.value : 0;
        
        setSeries([passed, failed]);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  const TOTAL = series?.reduce((a, b) => a + b, 0) || 1;

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4 fw-bold">
            تقسيم المحاولات حسب النجاح
          </h4>
          <div id="donut-chart" className="apex-charts">
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              height="250"
            />
          </div>
          <Row>
            <Col xs={6}>
              <div
                className="text-center mt-1"
                style={{ color: options.colors![0] }}
              >
                <p className="mb-2 text-truncate">
                  <i className="mdi mdi-circle font-size-10 me-1"></i>
                  {options.labels![0]}
                </p>
                <h5>
                  <CountUp
                    duration={1}
                    end={parseFloat((series[0] * 100 / TOTAL).toFixed(1))}
                    decimals={1}
                    suffix=" %"
                  />
                </h5>
              </div>
            </Col>
            <Col xs={6}>
              <div
                className="text-center mt-1"
                style={{ color: options.colors![1] }}
              >
                <p className="mb-2 text-truncate">
                  <i className="mdi mdi-circle font-size-10 me-1"></i>
                  {options.labels![1]}
                </p>
                <h5>
                  <CountUp
                    duration={1}
                    end={parseFloat((series[1] * 100 / TOTAL).toFixed(1))}
                    decimals={1}
                    suffix=" %"
                  />
                </h5>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PassPiChart;
