import React, { useState, useEffect, FC } from "react";
import { Row } from "reactstrap";
import DetailsCard from "./DetailsCard";
import { get } from "../../helpers/API";

interface StatsResponse {
  totalAttemptsCount: number;
  averageTime: number;
  averageAttempts: number;
  averageCorrectAnswers: number;
}

const MainStatsCards: FC = () => {
  const [totalAttemptsCount, setTotalAttemptsCount] = useState<number>(0);
  const [averageTime, setAverageTime] = useState<number>(0);
  const [averageAttempts, setAverageAttempts] = useState<number>(0);
  const [averageCorrectAnswers, setAverageCorrectAnswers] = useState<number>(0);

  useEffect(() => {
    get("/admin/main_stats")
      .then((response: StatsResponse) => {
        setTotalAttemptsCount(response.totalAttemptsCount);
        setAverageTime(response.averageTime);
        setAverageAttempts(response.averageAttempts);
        setAverageCorrectAnswers(response.averageCorrectAnswers);
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }, []); // The empty dependency array means this useEffect runs once when the component mounts

  return (
    <React.Fragment>
      <Row>
        <DetailsCard
          title={"مجموع المشاركات"}
          icon={"ri-team-fill"}
          counterOptions={{ end: totalAttemptsCount }}
        />
        <DetailsCard
          title={"متوسط الوقت"}
          icon={"ri-timer-fill"}
          counterOptions={{ end: averageTime, decimals: 1, suffix: " ثانية" }}
        />
        <DetailsCard
          title={"متوسط مرات المحاولة"}
          icon={"ri-repeat-2-fill"}
          counterOptions={{ end: averageAttempts, decimals: 1 }}
        />
        <DetailsCard
          title={"متوسط الاسئلة الصحيحة"}
          icon={"ri-check-double-fill"}
          counterOptions={{ end: averageCorrectAnswers, decimals: 1 }}
        />
      </Row>
    </React.Fragment>
  );
};

export default MainStatsCards;
