import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { get } from "../../helpers/API";

interface ScreenDetailsObj {
  name: string;
  data: number[];
}

const ScreenDetails: React.FC = () => {
  // Define the data series
  const [series, setSeries] = useState<ScreenDetailsObj[]>([]);

  // a use affect that pull the data from the api and set the data to the series
  useEffect(() => {
    get("/admin/screen_details")
      .then((response : ScreenDetailsObj[] ) => {
        console.log(response);
        setSeries(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Define chart options
  const options: ApexOptions = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: ["#28a745", "#dc3545"], // Green for Success, Red for Failure

    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: ["Screen 1", "Screen 2", "Screen 3"], // Replace with your screen IDs
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetY: 0,
    },
    tooltip: {
      y: {
        formatter: (val: string | number | number[]): string => {
          return val + " محاولة";
        },
      },
    },
  };
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4 fw-bold">مشاركات الشاشات</h4>
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ScreenDetails;
