import React, { useEffect, FC } from "react";
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";

type Props = {
  type?: string;
};

const SidebarContent: FC<Props> = ({ type }) => {
  const location = useLocation();

  useEffect(() => {
    initMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, location.pathname]);

  const initMenu = () => {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul!.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  };

  const activateParentDropdown = (item: HTMLAnchorElement) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active");
          // parent3.childNodes[0].classList.add("mm-active");
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title fw-bold">القائمة</li>
          <li>
            <Link to="/dashboard" className="waves-effect fw-bold">
              <i className="ri-dashboard-line"></i>
              <span className="ms-1">لوحة التحكم</span>
            </Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default SidebarContent;
