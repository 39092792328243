import React, { useState, useEffect, FC, FormEvent } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";

import { setToken } from "../helpers/AuthHelper";

import { post } from "../helpers/API";

import logo from "../assets/images/logo.png";

const Login: FC = () => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
    return () => {
      document.body.classList.remove("auth-body-bg");
    };
  }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    post("/auth/login", { username, password })
      .then((res) => {
        setToken(res.token);
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err);
        setLoginError("الرجاء التأكد من البريد الالكتروني وكلمة المرور");
        setPassword("");
      });
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <Link to="/" className="">
                            <img
                              src={logo}
                              alt=""
                              height="75"
                              className="auth-logo logo-dark mx-auto"
                            />
                            <img
                              src={logo}
                              alt=""
                              height="20"
                              className="auth-logo logo-light mx-auto"
                            />
                          </Link>
                          <h4 className="font-size-18 mt-4">مرحباً بك!</h4>
                          <p className="text-muted">الرجاء تسجيل الدخول</p>
                        </div>
                        {loginError && (
                          <Alert color="danger">{loginError}</Alert>
                        )}
                        <div className="p-2 mt-5">
                          <form
                            className="form-horizontal"
                            onSubmit={handleSubmit}
                            dir="ltr"
                          >
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="username">Email</Label>
                              <Input
                                type="text"
                                name="username"
                                value={username}
                                id="username"
                                placeholder="Enter username"
                                onChange={(e) => setUsername(e.target.value)}
                                required
                              />
                            </div>
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="userpassword">Password</Label>
                              <Input
                                type="password"
                                name="password"
                                value={password}
                                id="userpassword"
                                placeholder="Enter password"
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                            </div>
                            <div className="mt-4 text-center">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </Button>
                            </div>
                          </form>
                        </div>
                        <div className="mt-5 text-center">
                          <p>
                            Crafted with{" "}
                            <i className="mdi mdi-heart text-danger"></i> by{" "}
                            <a
                              href="https://vista.sa"
                              style={{ textDecoration: "none" }}
                            >
                              Vista.sa
                            </a>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
