import axios from "axios";
import { getToken } from "./AuthHelper";

//apply base url for axios
// const API_URL = "http://localhost:8080";
// const API_URL = "https://taqa-server-testing.azurewebsites.net"
const API_URL = "https://api.taqa.gov.sa"

const axiosApi = axios.create({
  baseURL: API_URL,
});

// put the token in request header
axiosApi.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url: string, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url: string, data: object, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}
